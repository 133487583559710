import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button } from 'antd';

const { Option } = Select;

class DrillValueEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    handleFormChange = e => {

        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received DrillValueEditor form values: ', values);

                this.props.onValidateFormValues(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (

            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} labelAlign="left" onChange={this.handleFormChange}>
                <Form.Item label="Drill lookup table">
                    {getFieldDecorator('drillLookupTable', {
                        initialValue: this.props.drillLookupTable,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Default drill value">
                    {getFieldDecorator('defaultDrillValue', {
                        initialValue: this.props.defaultDrillValue,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                    
                </Form.Item>
            </Form>

        );
    }
}

const WrappedDrillValueEditor = Form.create({ name: 'drillValueEditor' })(DrillValueEditor);

export default WrappedDrillValueEditor;