import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

const appConfig = require('./../../appsettings.json');

const adalConfig = {
    tenant: appConfig.AzureAd.TenantId,
    clientId: appConfig.AzureAd.ClientId,
    redirectUri: appConfig.AzureAd.Domain,
    postLogoutRedirectUri: appConfig.AzureAd.Domain,
    endpoints: {
        api: appConfig.AzureAd.Instance + appConfig.AzureAd.tenant
    },
    cacheLocation: 'sessionStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.clientId);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);