import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout as AntLayout, Menu, Card, Icon } from 'antd';
import { Steps, Tabs, Form, Checkbox, Button, Divider, Collapse, PageHeader, Dropdown, Input, Table, message, Tag } from 'antd';
import WrappedTopicSummaryEditor from './TopicSummaryEditor';
import LineChartCardEditor from './LineChartCardEditor';
import ColumnChartCardEditor from './ColumnChartCardEditor';
import MapCardEditor from './MapCardEditor';
import TextCardEditor from './TextCardEditor';
import TableCardEditor from './TableCardEditor';
import ScatterChartEditor from './ScatterChartEditor';
import Highlighter from 'react-highlight-words';
import { getToken } from './api-authorization/adalConfig';
import { Layout } from './Layout'

const { Header, Content, Sider } = AntLayout;
const { TabPane } = Tabs;

export class TopicEditor extends Component {

    static x = 1;

    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            alias: "",
            title: "",
            description: "",
            socialMediaImageUrl: "",
            availableLayers: [],
            selectedLayerIds: [],
            cardsLineChart: [],
            cardsColumnChart: [],
            cardsMap: [],
            cardsText: [],
            cardsTable: [],
            cardsScatterChart: [],
            loading: true,
            validationErrorCount: 0,
            layerSearchValue: "",
            allWrappedCardEditors: []
        };

        this.onLayerConfigChange = this.onLayerConfigChange.bind(this);
        this.onTopicSave = this.onTopicSave.bind(this);
        this.onValidateFormValues = this.onValidateFormValues.bind(this);
        this.onValidateLineChartFormValues = this.onValidateLineChartFormValues.bind(this);
        this.onValidateColumnChartFormValues = this.onValidateColumnChartFormValues.bind(this);
        this.onValidateMapFormValues = this.onValidateMapFormValues.bind(this);
        this.onValidateTextFormValues = this.onValidateTextFormValues.bind(this);
        this.onValidateTableFormValues = this.onValidateTableFormValues.bind(this);
        this.onValidateScatterChartFormValues = this.onValidateScatterChartFormValues.bind(this);
        this.createNewCard = this.createNewCard.bind(this);
        this.onNewCardMenuClick = this.onNewCardMenuClick.bind(this);

        this.getAllCardEditors = this.getAllCardEditors.bind(this);
        this.getCardEditorExtras = this.getCardEditorExtras.bind(this);
        this.changeCardOrder = this.changeCardOrder.bind(this);
        this.changeOrderAndShuffleCardsInState = this.changeOrderAndShuffleCardsInState.bind(this);
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        console.log("LOADING TOPIC EDITOR BY TOPIC ID", params.id);

        if (params.id) {
            // Get topic by id
            this.getTopicDetailsById(params.id);
        }
        else {
            this.getTopicDetailsById(-1);
        }

    }

    onLayerConfigChange(selectedRowKeys) {
        console.log("CHANGING LAYER CONFIG", selectedRowKeys);

        this.setState({ selectedLayerIds: selectedRowKeys });
    }

    onTopicSave() {
        console.log("SAVING TOPIC");

        this.saveTopic();
    }

    onValidateFormValues(formValues) {
        console.log(formValues);

        this.setState(formValues);
    }

    onValidateLineChartFormValues(newCardState) {
        console.log("UPDATING LINE CHART CARD STATE", newCardState)

        // TODO: apply this fix to other components
        if (newCardState.drillValues !== null && newCardState.drillValues !== undefined) {
            var drillValueIntArray = [];

            newCardState.drillValues.forEach(function (drillValue) {
                var drillValueInt = parseInt(drillValue.toString());

                drillValueIntArray.push(drillValueInt);
            });

            newCardState.drillValues = drillValueIntArray;
        }

        var lineChartCardsState = this.state.cardsLineChart;

        var indexToUpdate = lineChartCardsState.findIndex(function (item) {
            return item.guid === newCardState.guid;
        });

        lineChartCardsState[indexToUpdate] = newCardState;

        this.setState({ cardsLineChart: lineChartCardsState });

        this.getAllCardEditors();

        console.log("UPDATED TOPIC EDITOR STATE", this.state);
    }

    onValidateColumnChartFormValues(newCardState) {
        console.log("UPDATING COLUMN CHART CARD STATE", newCardState)

        // TODO: apply this fix to other components
        if (newCardState.drillValues !== null && newCardState.drillValues !== undefined) {
            var drillValueIntArray = [];

            newCardState.drillValues.forEach(function (drillValue) {
                var drillValueInt = parseInt(drillValue.toString());

                drillValueIntArray.push(drillValueInt);
            });

            newCardState.drillValues = drillValueIntArray;
        }

        var columnChartCardsState = this.state.cardsColumnChart;

        var indexToUpdate = columnChartCardsState.findIndex(function (item) {
            return item.guid === newCardState.guid;
        });

        columnChartCardsState[indexToUpdate] = newCardState;

        this.setState({ cardsColumnChart: columnChartCardsState });

        this.getAllCardEditors();

        console.log("UPDATED TOPIC EDITOR STATE", this.state);
    }

    onValidateScatterChartFormValues(newCardState) {
        console.log("UPDATING SCATTER CHART CARD STATE", newCardState)

        // TODO: apply this fix to other components
        if (newCardState.drillValues !== null && newCardState.drillValues !== undefined) {
            var drillValueIntArray = [];

            newCardState.drillValues.forEach(function (drillValue) {
                var drillValueInt = parseInt(drillValue.toString());

                drillValueIntArray.push(drillValueInt);
            });

            newCardState.drillValues = drillValueIntArray;
        }

        if (newCardState.secondaryDrillValues !== null && newCardState.secondaryDrillValues !== undefined) {
            var secondaryDrillValueIntArray = [];

            newCardState.secondaryDrillValues.forEach(function (secondaryDrillValue) {
                var secondaryDrillValueInt = parseInt(secondaryDrillValue.toString());

                secondaryDrillValueIntArray.push(secondaryDrillValueInt);
            });

            newCardState.secondaryDrillValues = secondaryDrillValueIntArray;
        }

        var scatterChartCardsState = this.state.cardsScatterChart;

        var indexToUpdate = scatterChartCardsState.findIndex(function (item) {
            return item.guid === newCardState.guid;
        });

        scatterChartCardsState[indexToUpdate] = newCardState;

        this.setState({ cardsScatterChart: scatterChartCardsState });

        this.getAllCardEditors();

        console.log("UPDATED TOPIC EDITOR STATE", this.state);
    }

    onValidateMapFormValues(newCardState) {
        console.log("UPDATING MAP CARD STATE", newCardState)

        // TODO: apply this fix to other components
        if (newCardState.drillValues !== null && newCardState.drillValues !== undefined) {
            var drillValueIntArray = [];

            newCardState.drillValues.forEach(function (drillValue) {
                var drillValueInt = parseInt(drillValue.toString());

                drillValueIntArray.push(drillValueInt);
            });

            newCardState.drillValues = drillValueIntArray;
        }

        var mapCardsState = this.state.cardsMap;

        var indexToUpdate = mapCardsState.findIndex(function (item) {
            return item.guid === newCardState.guid;
        });

        mapCardsState[indexToUpdate] = newCardState;

        this.setState({ cardsMap: mapCardsState });

        this.getAllCardEditors();

        console.log("UPDATED TOPIC EDITOR STATE", this.state);
    }

    onValidateTextFormValues(newCardState) {
        console.log("UPDATING TEXT CARD STATE", newCardState)

        var textCardsState = this.state.cardsText;

        var indexToUpdate = textCardsState.findIndex(function (item) {
            return item.guid === newCardState.guid;
        });

        textCardsState[indexToUpdate] = newCardState;

        this.setState({ cardsText: textCardsState });

        this.getAllCardEditors();

        console.log("UPDATED TOPIC EDITOR STATE", this.state);
    }

    onValidateTableFormValues(newCardState) {
        console.log("UPDATING TABLE CARD STATE", newCardState)

        // TODO: apply this fix to other components
        if (newCardState.drillValues !== null && newCardState.drillValues !== undefined) {
            var drillValueIntArray = [];

            newCardState.drillValues.forEach(function (drillValue) {
                var drillValueInt = parseInt(drillValue.toString());

                drillValueIntArray.push(drillValueInt);
            });

            newCardState.drillValues = drillValueIntArray;
        }

        var tableCardsState = this.state.cardsTable;

        var indexToUpdate = tableCardsState.findIndex(function (item) {
            return item.guid === newCardState.guid;
        });

        const permittedTypeBehaviours = [
            { type: "MAP_DATA", behaviour: "top" },
            { type: "MAP_DATA", behaviour: "bottom" },
            { type: "CHART_DATA", behaviour: "top" },
            { type: "CHART_DATA", behaviour: "bottom" },
            { type: "TABLE_DATA", behaviour: "multiValue" },
        ];

        const validBehaviour = permittedTypeBehaviours.filter(value => { return value.type === newCardState.tableType && value.behaviour === newCardState.tableBehaviour});

        if (!validBehaviour) {
            newCardState.tableBehaviour = "default";
        }

        tableCardsState[indexToUpdate] = newCardState;

        this.setState({ cardsTable: tableCardsState });

        this.getAllCardEditors();

        console.log("UPDATED TOPIC EDITOR STATE", this.state);
    }

    createNewCard() {
        var currentCardState = this.state.cardsLineChart;
        currentCardState.push(this.state.cardsLineChart[0]);

        this.setState({ cardsLineChart: currentCardState });
    }

    onNewCardMenuClick(e) {
        console.log('INSERTING NEW CARD', e);

        this.insertNewDefaultCardByType(e.key);


    }



    async deleteCard(cardId, cardGuid, cardArray, event) {

        event.stopPropagation();

        if (cardId === 0) {
            var indexToRemove = cardArray.findIndex(function (item) {
                return item.guid === cardGuid;
            });

            //cardArray.splice(indexToRemove, 1);
            // TODO: fix deletion of cards that aren't on DB
        }
        else {
            const token = getToken();
            const response = await fetch('topics/card/delete?id=' + cardId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.text().then(this.getTopicDetailsById(this.state.id));

            message.success('Card was deleted.');
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    changeCardOrder = (card, changeType, formsValidationEvent, event) => {
        event.stopPropagation();

        if (changeType === "moveUp") {
            var oldCardOrder = card.order;
            var newCardOrder = card.order - 1;

            this.changeOrderAndShuffleCardsInState(card, formsValidationEvent, oldCardOrder, newCardOrder, changeType);
        }
        else if (changeType === "moveDown") {
            var oldCardOrder = card.order;
            var newCardOrder = card.order + 1;

            this.changeOrderAndShuffleCardsInState(card, formsValidationEvent, oldCardOrder, newCardOrder, changeType);
        }
        else if (changeType === "moveToTop") {
            var oldCardOrder = card.order;
            var newCardOrder = 0;

            this.changeOrderAndShuffleCardsInState(card, formsValidationEvent, oldCardOrder, newCardOrder, changeType);
        }
        else if (changeType === "moveToBottom") {
            var oldCardOrder = card.order;
            var newCardOrder = this.state.allWrappedCardEditors.length - 1;

            this.changeOrderAndShuffleCardsInState(card, formsValidationEvent, oldCardOrder, newCardOrder, changeType);
        }
    };

    changeOrderAndShuffleCardsInState(card, formsValidationEvent, oldCardOrder, newCardOrder, changeType) {
        if (newCardOrder >= 0 && newCardOrder <= this.state.allWrappedCardEditors.length - 1) {
            var cardWithNewOrderExists = false;
            var indexOfExistingCardWithNewOrder = 0;

            for (var i = 0; i < this.state.allWrappedCardEditors.length; i++) {
                var currentCardOrder = this.state.allWrappedCardEditors[i].props.children.props.cardEditorState.order;

                if (currentCardOrder == newCardOrder) {
                    cardWithNewOrderExists = true;
                    indexOfExistingCardWithNewOrder = i;
                }
            }

            if (changeType === "moveToTop") {
                for (var i = 0; i < this.state.allWrappedCardEditors.length; i++) {
                    var currentCardState = this.state.allWrappedCardEditors[i].props.children.props.cardEditorState;
                    var currentCardOrder = currentCardState.order;
                    var validCurrentCardFormValues = this.state.allWrappedCardEditors[i].props.children.props.onValidateFormValues;

                    if (currentCardOrder < oldCardOrder) {
                        currentCardState.order = currentCardOrder + 1;
                        validCurrentCardFormValues(currentCardState);
                    }
                }
            }
            else if (changeType === "moveToBottom") {
                for (var i = 0; i < this.state.allWrappedCardEditors.length; i++) {
                    var currentCardState = this.state.allWrappedCardEditors[i].props.children.props.cardEditorState;
                    var currentCardOrder = currentCardState.order;
                    var validCurrentCardFormValues = this.state.allWrappedCardEditors[i].props.children.props.onValidateFormValues;

                    if (currentCardOrder > oldCardOrder) {
                        currentCardState.order = currentCardOrder - 1;
                        validCurrentCardFormValues(currentCardState);
                    }
                }
            }
            else if (cardWithNewOrderExists) {
                var stateFromExistingCardWithNewOrder = this.state.allWrappedCardEditors[indexOfExistingCardWithNewOrder].props.children.props.cardEditorState;
                var validateFormValuesEventFromExistingCardWithNewOrder = this.state.allWrappedCardEditors[indexOfExistingCardWithNewOrder].props.children.props.onValidateFormValues;

                stateFromExistingCardWithNewOrder.order = oldCardOrder;
                validateFormValuesEventFromExistingCardWithNewOrder(stateFromExistingCardWithNewOrder);
            }

            card.order = newCardOrder;
            formsValidationEvent(card);
        }
    }

    getCardEditorExtras = (card, cardArray, formsValidationEvent) => {
        var scope = this;

        return (
            <Fragment>
                {card.id === 0 && <Tag style={{ padding: "1px 8px", float: "left" }} color="gold">Not yet saved to database</Tag>}
                <Button.Group size="small">
                    <Button onClick={e => { scope.changeCardOrder(card, "moveToBottom", formsValidationEvent, e) }} size="small" icon="vertical-align-bottom" title="Move to bottom"></Button>
                    <Button onClick={e => { scope.changeCardOrder(card, "moveDown", formsValidationEvent, e) }} size="small" icon="arrow-down" title="Move down"></Button>
                    <Button onClick={e => { scope.changeCardOrder(card, "moveUp", formsValidationEvent, e) }} size="small" icon="arrow-up" title="Move up"></Button>
                    <Button onClick={e => { scope.changeCardOrder(card, "moveToTop", formsValidationEvent, e) }} size="small" icon="vertical-align-top" title="Move to top"></Button>
                </Button.Group>
                <Button.Group size="small" style={{ marginLeft: '10px' }}>
                    <Button onClick={e => { scope.deleteCard(card.id, card.guid, cardArray, e) }} size="small" icon="delete">Delete</Button>
                </Button.Group>
            </Fragment>);
    };

    getAllCardEditors = () => {
        var scope = this;
        var allCardEditors = [];

        this.state.cardsLineChart.map(function (card, index) {
            allCardEditors.push(<Collapse.Panel header={(card.order + 1) + ". " + card.title + " (Line chart)"} key={"LineChartCard" + card.guid} extra={scope.getCardEditorExtras(card, scope.state.cardsLineChart, scope.onValidateLineChartFormValues)} >
                <LineChartCardEditor
                    cardEditorState={card}
                    onValidateFormValues={scope.onValidateLineChartFormValues}
                    dataSets={scope.state.dataSets}
                />
            </Collapse.Panel>);
        });

        this.state.cardsColumnChart.map(function (card, index) {
            allCardEditors.push(<Collapse.Panel header={(card.order + 1) + ". " + card.title + " (Column chart)"} key={"ColumnChartCard" + card.guid} extra={scope.getCardEditorExtras(card, scope.state.cardsColumnChart, scope.onValidateColumnChartFormValues)} >
                <ColumnChartCardEditor
                    cardEditorState={card}
                    onValidateFormValues={scope.onValidateColumnChartFormValues}
                    dataSets={scope.state.dataSets}
                />
            </Collapse.Panel>);
        });

        this.state.cardsMap.map(function (card, index) {
            allCardEditors.push(<Collapse.Panel header={(card.order + 1) + ". " + card.title + " (Map)"} key={"MapCard" + card.guid} extra={scope.getCardEditorExtras(card, scope.state.cardsMap, scope.onValidateMapFormValues)} >
                <MapCardEditor
                    cardEditorState={card}
                    onValidateFormValues={scope.onValidateMapFormValues}
                    dataSets={scope.state.dataSets}
                />
            </Collapse.Panel>);
        });

        this.state.cardsText.map(function (card, index) {
            allCardEditors.push(<Collapse.Panel header={(card.order + 1) + ". " + card.title + " (Text)"} key={"TextCard" + card.guid} extra={scope.getCardEditorExtras(card, scope.state.cardsText, scope.onValidateTextFormValues)} >
                <TextCardEditor
                    cardEditorState={card}
                    onValidateFormValues={scope.onValidateTextFormValues}
                />
            </Collapse.Panel>);
        });

        this.state.cardsTable.map(function (card, index) {
            allCardEditors.push(<Collapse.Panel header={(card.order + 1) + ". " + card.title + " (Table)"} key={"TableCard" + card.guid} extra={scope.getCardEditorExtras(card, scope.state.cardsTable, scope.onValidateTableFormValues)} >
                <TableCardEditor
                    cardEditorState={card}
                    onValidateFormValues={scope.onValidateTableFormValues}
                    dataSets={scope.state.dataSets}
                />
            </Collapse.Panel>);
        });

        this.state.cardsScatterChart.map(function (card, index) {
            allCardEditors.push(<Collapse.Panel header={(card.order + 1) + ". " + card.title + " (Scatter chart)"} key={"ScatterChartCard" + card.guid} extra={scope.getCardEditorExtras(card, scope.state.cardsScatterChart, scope.onValidateScatterChartFormValues)} >
                <ScatterChartEditor
                    cardEditorState={card}
                    onValidateFormValues={scope.onValidateScatterChartFormValues}
                    dataSets={scope.state.dataSets}
                />
            </Collapse.Panel>);
        });

        if (allCardEditors.length == 0) {
            return [];
        }

        // Sort by order property
        allCardEditors = allCardEditors.sort((a, b) => (a.props.children.props.cardEditorState.order > b.props.children.props.cardEditorState.order) ? 1 : -1);

        this.setState({ allWrappedCardEditors: allCardEditors });
    }

    render() {

        var scope = this;

        const newCardMenu = (
            <Menu onClick={this.onNewCardMenuClick}>
                <Menu.Item key="LINE_CHART">
                    <Icon type="line-chart" />
                    Line chart
                </Menu.Item>
                <Menu.Item key="COLUMN_CHART">
                    <Icon type="bar-chart" />
                    Column chart
                </Menu.Item>
                <Menu.Item key="SCATTER_CHART">
                    <Icon type="dot-chart" />
                    Scatter chart
                </Menu.Item>
                <Menu.Item key="MAP">
                    <Icon type="global" />
                    Map
                </Menu.Item>
                <Menu.Item key="TABLE">
                    <Icon type="table" />
                    Table
                </Menu.Item>
                <Menu.Item key="TEXT">
                    <Icon type="align-left" />
                    Text
                </Menu.Item>
            </Menu>
        );

        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                ...this.getColumnSearchProps('id')
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name')
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                ...this.getColumnSearchProps('description')
            },
            {
                title: 'Scale',
                dataIndex: 'scale',
                key: 'scale'
            },
            {
                title: 'RegionBias',
                dataIndex: 'regionBias',
                key: 'regionBias',
                ...this.getColumnSearchProps('regionBias')
            }
        ];

        const rowSelection = {
            selectedRowKeys: scope.state.selectedLayerIds,
            onChange: (selectedRowKeys, selectedRows) => { scope.onLayerConfigChange(selectedRowKeys) }
        };

        return (
            <Fragment>
                <Layout
                    onBack={() => this.props.history.push('/dashboard_builder')}
                    title={this.state.id != -1 ? this.state.title : "Create a new topic"}
                    extra={[
                        <Button type="primary" icon="save" onClick={this.onTopicSave}>
                            Save topic
                        </Button>
                    ]}
                >
                    <Card>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Summary" key="1">
                                <WrappedTopicSummaryEditor
                                    alias={this.state.alias}
                                    title={this.state.title}
                                    description={this.state.description}
                                    dashboardDescription={this.state.dashboardDescription}
                                    socialMediaImageUrl={this.state.socialMediaImageUrl}
                                    tags={this.state.tags}
                                    onValidateFormValues={this.onValidateFormValues}
                                />
                            </TabPane>
                            <TabPane tab={"Layers (" + this.state.selectedLayerIds.length + ")"} key="2">

                                <Fragment>



                                    <Table rowKey="id" size="small" pagination={false} bordered={true} columns={columns} dataSource={this.state.availableLayers} rowSelection={rowSelection} />




                                </Fragment>

                            </TabPane>
                            <TabPane tab={"Cards (" + (this.state.cardsLineChart.length + this.state.cardsColumnChart.length + this.state.cardsScatterChart.length + this.state.cardsMap.length + this.state.cardsText.length + this.state.cardsTable.length) + ")"} key="3">
                                <div style={{ marginBottom: '11px', display: 'inline-block', width: '100%' }}>
                                    <Dropdown overlay={newCardMenu}>
                                        <Button style={{ float: 'right' }}>
                                            <Icon type="plus" /> Create a new card
                                        </Button>
                                    </Dropdown>
                                </div>

                                <Collapse bordered={true} accordion>
                                    {this.state.allWrappedCardEditors}
                                </Collapse>
                            </TabPane>
                        </Tabs>
                    </Card>
                </Layout>
            </Fragment>

        );
    }

    async insertNewDefaultCardByType(cardType) {
        const token = getToken();
        const response = await fetch('topics/default_card?cardType=' + cardType, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        if (cardType === "LINE_CHART") {
            var cardsLineChartState = this.state.cardsLineChart;

            data.order = this.state.allWrappedCardEditors.length;

            cardsLineChartState.push(data);

            this.setState({ cardsLineChart: cardsLineChartState });
        }
        else if (cardType === "COLUMN_CHART") {
            var cardsColumnChartState = this.state.cardsColumnChart;
            cardsColumnChartState.push(data);

            data.order = this.state.allWrappedCardEditors.length;

            this.setState({ cardsColumnChart: cardsColumnChartState });
        }
        else if (cardType === "SCATTER_CHART") {
            var cardsScatterChartState = this.state.cardsScatterChart;
            cardsScatterChartState.push(data);

            data.order = this.state.allWrappedCardEditors.length;

            this.setState({ cardsScatterChart: cardsScatterChartState });
        }
        else if (cardType == "MAP") {
            var cardsMapState = this.state.cardsMap;
            cardsMapState.push(data);

            data.order = this.state.allWrappedCardEditors.length;

            this.setState({ cardsMap: cardsMapState });
        }
        else if (cardType === "TEXT") {
            var cardsTextState = this.state.cardsText;
            cardsTextState.push(data);

            data.order = this.state.allWrappedCardEditors.length;

            this.setState({ cardsText: cardsTextState });
        }
        else if (cardType === "TABLE") {
            var cardsTableState = this.state.cardsTable;
            cardsTableState.push(data);

            data.order = this.state.allWrappedCardEditors.length;

            this.setState({ cardsTable: cardsTableState });
        }

        this.getAllCardEditors();
    }

    async getTopicDetailsById(topicId) {
        const token = getToken();
        const response = await fetch('topics/details?id=' + topicId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState(data);

        this.setState({ loading: false });

        this.getAllCardEditors();
    }

    async saveTopic() {
        const token = getToken();

        const response = await fetch('topics/save', {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(this.state)
        });

        const data = response.json().then(this.props.history.push('/dashboard_builder')).then(message.success('Your changes have been saved.'));
    }
}
