import React, { Component } from 'react';
import { Card, Button } from 'antd';
import { Fragment } from 'react';
import { getToken } from './api-authorization/adalConfig';
import { Layout } from './Layout'

export class DevTools extends Component {
    constructor(props) {
        super(props);

        this.state = { };
    }

    componentDidMount() {
        this.getEnvironmentInfo();
    }

    render() {
        return (
            <Fragment>
                <Layout title="Dev Tools">

                    <Card title="Environment Info">
                        {
                            this.state.environmentInfo &&
                            this.state.environmentInfo.map(function (entry, index) {
                                return <p aria-live="polite">{entry.Key} <strong>{entry.Value}</strong></p>
                            })
                        }
                    </Card>

                    <Button key="wakeup" type="primary" onClick={this.wakeDatabases}>
                        Wake Idle Databases
                    </Button>
                </Layout>
            </Fragment>
        );
    }

    async getEnvironmentInfo() {
        const token = getToken();

        const response = await fetch('devtools/environmentinfo', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        const data = await response.json();

        this.setState({ environmentInfo: data });

        this.setState({ loading: false });
    }

    async wakeDatabases() {
        const token = getToken();

        const response = await fetch('devtools/wakedatabases', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
    }
}