import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

import logo from '../assets/images/gavurin_logo_small.png';

import { Layout, Menu, Breadcrumb, Icon } from 'antd';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.state = {
    };
  }

  render () {
    return (


        <Header className="header">
            <div className="headerLogo" style={{ float: 'left', paddingRight: '20px', paddingLeft: '20px', marginRight: '10px' }}>
                <img src={logo} height={32} />
            </div>
            <Menu
                theme="dark"
                mode="horizontal"
                selectable={false}
                style={{ lineHeight: '64px', float: 'left' }}
            >
                <Menu.Item key="home">
                    <a href="/"><Icon type="home" /> Home</a>
                </Menu.Item>
                <Menu.Item key="datasets">
                    <a href="/dataset_builder"><Icon type="database" /> Datasets</a>
                </Menu.Item>
                <Menu.Item key="dashboards">
                    <a href="/dashboard_builder"><Icon type="dashboard" /> Dashboards</a>
                </Menu.Item>
                <Menu.Item key="reports">
                    <a href="/reports"><Icon type="line-chart" /> Usage Stats</a>
                </Menu.Item>
                <Menu.Item key="dev-tools">
                    <a href="/dev_tools"><Icon type="tool" /> Dev Tools</a>
                </Menu.Item>

            </Menu>
            <LoginMenu />
        </Header>
    );
  }
}
