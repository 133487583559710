import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button } from 'antd';

const { Option } = Select;

class ValidityRuleEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.props.validityRuleEditorState;
    }

    componentDidMount() {

    }

    handleFormChange = e => {

        this.props.form.validateFields((err, values) => {
            this.setState(values, () => {
                this.props.onValidateFormValues(this.state);
            });
        });
    };

    //static getDerivedStateFromProps(nextProps, prevState) {
    //    if (nextProps.validityRuleEditorState !== prevState) {
    //        return nextProps.validityRuleEditorState;
    //    }
    //    else return null;
    //}

    render() {
        const { getFieldDecorator } = this.props.form;

        return (

            <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }} labelAlign="left" onChange={this.handleFormChange}>
                <Form.Item label="Dates">
                    {getFieldDecorator('dates', {
                        initialValue: this.state.dates,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Drill values">
                    {getFieldDecorator('drillValues', {
                        initialValue: this.state.drillValues,
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Layers">
                    {getFieldDecorator('layers', {
                        initialValue: this.state.layers,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24, offset: 0 }}>

                </Form.Item>
            </Form>

        );
    }
}

const WrappedValidityRuleEditor = Form.create({ name: 'validityRuleEditor' })(ValidityRuleEditor);

export default WrappedValidityRuleEditor;