import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { useLocation } from 'react-router-dom'

import { Layout as AntLayout, Menu, Breadcrumb, PageHeader } from 'antd';

const { Header, Content, Footer } = AntLayout;

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
        <AntLayout className="layout" style={{ minHeight: '100%' }}>
            <NavMenu />
            <PageHeader
                style={{
                    borderBottom: '1px solid rgb(235, 237, 240)',
                    background: '#ffffff',
                    padding: '24px 140px'
                }}
                title={this.props.title}
                subTitle={this.props.subTitle}
                extra={this.props.extra}
                onBack={this.props.onBack}
            />
            {this.props.additionalPageHeaderContent &&
                <PageHeader
                    style={{
                        borderBottom: '1px solid rgb(235, 237, 240)',
                        background: '#ffffff',
                        padding: '24px 140px'
                    }}
                    className="additional-page-header-content"
                    footer={this.props.additionalPageHeaderContent}
                />
            }
            <Content style={{ padding: '0 120px' }}>
                <Content
                    style={{
                        margin: 0,
                        marginTop: 24,
                        minHeight: '100%'
                    }}
                >
                    {this.props.children}
                </Content>
            </Content>
            <Footer style={{ textAlign: 'center', margin: '0px 120px' }}>&copy; 2020 - Gavurin Ltd</Footer>
        </AntLayout>
    );
  }
}
