import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button } from 'antd';

const { Option } = Select;

class AggregationRuleEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    handleFormChange = e => {

        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received AggregationRuleEditor form values: ', values);

                this.props.onValidateFormValues(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (

            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} labelAlign="left" onChange={this.handleFormChange}>
                <Form.Item label="Aggregation rule type">
                    {getFieldDecorator('aggregationRuleType', {
                        initialValue: this.props.aggregationRules.length > 0 ? this.props.aggregationRules[0].aggregationRuleType : "",
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Field">
                    {getFieldDecorator('field', {
                        initialValue: this.props.aggregationRules.length > 0 ? this.props.aggregationRules[0].field : "",
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Domain">
                    {getFieldDecorator('domain', {
                        initialValue: this.props.aggregationRules.length > 0 ? this.props.aggregationRules[0].domain : "",
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                    
                </Form.Item>
            </Form>

        );
    }
}

const WrappedAggregationRuleEditor = Form.create({ name: 'aggregationRuleEditor' })(AggregationRuleEditor);

export default WrappedAggregationRuleEditor;