import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button } from 'antd';
import { getToken } from './api-authorization/adalConfig';

const { Option } = Select;

class DrillValueSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            drillDetails: []
        };

        this.getDrillDetailsByDatasetId = this.getDrillDetailsByDatasetId.bind(this);
    }

    componentDidMount() {
        if (this.props.datasetId) {
            this.getDrillDetailsByDatasetId(this.props.datasetId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.datasetId !== this.props.datasetId) {
            if (this.props.datasetId) {
                this.getDrillDetailsByDatasetId(this.props.datasetId);
            }
        }
    }

    async getDrillDetailsByDatasetId(datasetId) {
        const token = getToken();
        const response = await fetch('topics/drill_details?datasetId=' + datasetId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState({ drillDetails: data });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        var fieldDecoratorKey = this.props.isSecondary !== null && this.props.isSecondary !== undefined & this.props.isSecondary ? "secondaryDrillValues" : "drillValues";

        return (

            <Fragment>

                {this.state.drillDetails &&
                    getFieldDecorator(fieldDecoratorKey, {
                        initialValue: this.props.drillValues.map(String),
                        rules: [{ required: true, message: 'Select drill value(s)' }]
                    })(
                        <Select
                            mode="multiple"
                            placeholder="Select drill values"
                            style={{ width: '100%' }}
                            onBlur={this.props.onBlurHandler}
                        >
                            {this.state.drillDetails.map((drillValue, i) => <Option key={drillValue.Key} title={drillValue.Value}>{drillValue.Value}</Option>)}
                        </Select>
                    )
                }
    
            </Fragment>

        );
    }
}

export default DrillValueSelector;