import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button } from 'antd';

const { Option } = Select;

class DatasetSummaryEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    handleFormChange = e => {

        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received DatasetSummaryEditor form values: ', values);

                this.props.onValidateFormValues(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (

            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} labelAlign="left" onChange={this.handleFormChange}>
                <Form.Item label="Common name">
                    {getFieldDecorator('commonName', {
                        initialValue: this.props.commonName,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Description">
                    {getFieldDecorator('description', {
                        initialValue: this.props.description,
                        rules: []
                    })(<Input.TextArea rows={5} />)}
                </Form.Item>
                <Form.Item label="Description hint">
                    {getFieldDecorator('descriptionHint', {
                        initialValue: this.props.descriptionHint,
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Keywords">
                    {getFieldDecorator('keywords', {
                        initialValue: this.props.keywords,
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Sources">
                    {getFieldDecorator('sources', {
                        initialValue: this.props.sources,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Stored procedure name">
                    {getFieldDecorator('storedProcedureName', {
                        initialValue: this.props.storedProcedureName,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Build domain">
                    {getFieldDecorator('buildDomain', {
                        initialValue: this.props.buildDomain,
                        rules: []
                    })(<Input disabled />)}
                </Form.Item>
                <Form.Item label="Map presentation name">
                    {getFieldDecorator('mapPresentationName', {
                        initialValue: this.props.mapPresentationName,
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Chart Y-axis label">
                    {getFieldDecorator('chartYAxisLabel', {
                        initialValue: this.props.chartYAxisLabel,
                        rules: []
                    })(<Input />)}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
                    
                </Form.Item>
            </Form>

        );
    }
}

const WrappedDatasetSummaryEditor = Form.create({ name: 'datasetSummaryEditor' })(DatasetSummaryEditor);

export default WrappedDatasetSummaryEditor;