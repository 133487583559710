import React, { Component, Fragment } from 'react';
import { Card, Button, List, Avatar, Tag, message } from 'antd';
import { getToken } from './api-authorization/adalConfig';
import { Layout } from './Layout'

export class TopicList extends Component {

    constructor(props) {
        super(props);
        this.state = { topics: [], loading: true };

        this.getTopics = this.getTopics.bind(this);
    }

    componentDidMount() {
        this.getTopics();
    }

    async getTopics() {
        const token = getToken();
        const response = await fetch('topics/all', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ topics: data, loading: false });
    }

    async deleteTopic(topicId) {
        const token = getToken();
        const response = await fetch('topics/delete?id=' + topicId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.text().then(this.getTopics());

        message.success('Topic was deleted.');
    }

    async transferTopicToProduction(topicId) {
        const token = getToken();
        const response = await fetch('topics/transfer?id=' + topicId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.text().then(this.getTopics());

        message.success('Topic was transferred to production.');
    }

    async deleteTopicFromProduction(topicId) {
        const token = getToken();
        const response = await fetch('topics/delete_from_production?id=' + topicId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.text().then(this.getTopics());

        message.success('Topic was deleted from production.');
    }

    render() {
        return (
            <Fragment>
                <Layout title="Dashboards" extra={[
                    <Button key="1" type="primary" icon="plus" href="dashboard_builder/create">
                        Create a new topic
                        </Button>
                ]}>
                    <Card title={"Lite Dashboards (" + this.state.topics.length + ")"}>
                        <List
                    className="demo-loadmore-list"
                    loading={this.state.loading}
                    itemLayout="horizontal"
                    dataSource={this.state.topics}
                    renderItem={item => (
                        <List.Item
                            actions={[<Button type="link" icon="edit" href={"dashboard_builder/edit/" + item.id}>Edit</Button>, <Button onClick={() => { this.transferTopicToProduction(item.id) }} type="link" icon="file-sync">Transfer to production</Button>, <Button onClick={() => { this.deleteTopicFromProduction(item.id) }} disabled={!item.existsOnProduction} type="link" icon="delete">Delete from production</Button>, <Button onClick={() => { this.deleteTopic(item.id) }} type="link" icon="delete">Delete</Button>]}
                                >
                                        <List.Item.Meta
                                        avatar={
                                            <Avatar size="large" icon="file-search" />
                                        }
                                        title={<span>{item.title} (Id = {item.id})</span>}
                                description={
                                    <Fragment>
                                        {item.existsOnProduction && item.dateLastModified === item.dateLastModifiedOnProduction && <div><Tag color="green">On production (in sync)</Tag></div>}
                                        {item.existsOnProduction && item.dateLastModified !== item.dateLastModifiedOnProduction && <div><Tag color="gold">On production (out of sync)</Tag></div>}
                                        {!item.existsOnProduction && <div><Tag>Not on production</Tag></div>}
                                        <p></p>
                                        <div>Last modified: {item.dateLastModified}</div>
                                        {item.existsOnProduction && <Fragment>Last modified on production: {item.dateLastModifiedOnProduction}</Fragment>}
                                        <p></p>
                                        {item.description}
                                    </Fragment>
                                }
                                        />
                                </List.Item>
                            )}
                        />
                        </Card>
                </Layout>
            </Fragment>
        );
    }
}
