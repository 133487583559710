import React, { Component } from 'react';
import { Result, DatePicker, Statistic, Radio, Button, Card, Row, Col, Spin, Icon } from 'antd';
import { Fragment } from 'react';
import { getToken } from './api-authorization/adalConfig';
import moment from 'moment';
import GatChart from './GatChart';
import { Layout } from './Layout';
import { saveAs } from 'file-saver';


const uniqueVisitorsChartConfig = {
    xField: 'date',
    yField: 'count',
    forceFit: true,
    seriesField: 'userType',
    legend: {
        visible: true,
        offsetX: 24,
        offsetY: 24
    },
    xAxis: {
        type: 'time',
        mask: 'DD/MM/YYYY',
        visible: true,
        grid: {
            visible: false,
        },
        tickLine: {
            visible: true,
        },
        label: {
            visible: true,
            autoRotate: false,
            autoHide: false
        },
        title: {
            visible: false,
            offset: 12,
        },
    },
    yAxis: {
        label: {
            visible: true,
            autoRotate: false,
            autoHide: false
        },
        title: {
            visible: true
        },
    },
    meta: {
        date: {
            alias: 'Date'
        },
        count: {
            alias: 'Unique visitors'
        }
    },
    animation: {
        enter: {
            animation: 'clipingWithData',
        },
    },
    interactions: [
        {
            type: 'slider',
            cfg: {
                start: 0.0,
                end: 1.0,
                height: 48,
                textStyle: {
                    fontSize: 0
                },
                handlerStyle: {
                    height: 48
                }
            }
        },
    ]
}

const browsersChartConfig = {
    padding: 'auto',
    xField: 'count',
    yField: 'browser',
    forceFit: true,
    meta: {
        browser: {
            alias: 'Browser'
        },
        count: {
            alias: 'Unique visitor count'
        }
    },
    interactions: [
        {
            type: 'scrollbar',
            cfg: {
                type: 'vertical',
            },
        },
    ]
}

const operatingSystemsChartConfig = {
    padding: 'auto',
    xField: 'count',
    yField: 'os',
    forceFit: true,
    meta: {
        os: {
            alias: 'Operating System'
        },
        count: {
            alias: 'Unique visitor count'
        }
    },
    interactions: [
        {
            type: 'scrollbar',
            cfg: {
                type: 'vertical',
            },
        },
    ]
}

const devicesChartConfig = {
    padding: 'auto',
    xField: 'count',
    yField: 'device',
    forceFit: true,
    meta: {
        device: {
            alias: 'Device'
        },
        count: {
            alias: 'Unique visitor count'
        }
    },
    interactions: [
        {
            type: 'scrollbar',
            cfg: {
                type: 'vertical',
            },
        },
    ]
}

const referersConfig = {
    padding: 'auto',
    xField: 'count',
    yField: 'referer',
    forceFit: true,
    meta: {
        referer: {
            alias: 'Referer'
        },
        count: {
            alias: 'Unique visitor count'
        }
    },
    interactions: [
        {
            type: 'scrollbar',
            cfg: {
                type: 'vertical',
            },
        },
    ]
}

const topicsConfig = {
    padding: 'auto',
    xField: 'count',
    yField: 'topic',
    forceFit: true,
    colorField: 'category',
    legend: {
        visible: true,
        offsetX: 24,
        offsetY: 24
    },
    meta: {
        topic: {
            alias: 'Topic'
        },
        count: {
            alias: 'Unique visitor count'
        },
        category: {
            alias: 'Category'
        }
    },
    interactions: [
        {
            type: 'scrollbar',
            cfg: {
                type: 'vertical',
            },
        },
    ]
}

const usageStats = {
    xField: 'date',
    yField: 'count',
    legend: {
        position: 'top',
        flipPage: true,
        pageNavigator: {
            marker: {
                style: {
                    fill: 'rgba(0,0,0,0.65)',
                },
            },
        },
        visible: true,
        offsetY: 24
    },
    isStack: true,
    seriesField: 'entry',
    colorField: 'entry',
    xAxis: {
        label: {
            autoRotate: false,
            autoHide: true,
            formatter: (text, item, idx) => {
                var options = {
                    year: "2-digit",
                    month: "numeric",
                    day: "numeric",
                };
                var date = (new Date(text.split('T')[0])).toLocaleDateString("en", options);
                return date;
            }
        },
    },
    interactions: [
        {
            type: 'slider',
            cfg: {
                type: 'horizontal',
            },
        },
    ]
}

const topicUsageConfig = {
    padding: 'auto',
    xField: 'visits',
    yField: 'name',
    isStack: true,
    legend: {
        visible: true,
        offsetX: 24,
        offsetY: 24,
        text: {
            formatter: (text, cfg) => {
                if (text === "Id") {
                    return "Selected Dashboard Id";
                }
                if (text === "Topic") {
                    return "Used Topic Selector";
                }
                return "";
            },
        },
    },
    seriesField: 'type',
    label: {
        position: 'middle', // 'left', 'middle', 'right'
        layout: [
            { type: 'interval-adjust-position' },
            { type: 'interval-hide-overlap' },
            { type: 'adjust-color' },
        ],
    },
    colorField: 'type',
    interactions: [
        {
            type: 'scrollbar',
            cfg: {
                type: 'vertical',
            },
        },
    ]
}

const gridStyle = {
    width: '25%',
    textAlign: 'center',
};

const infoCardStyle = {
    marginBottom: 24
};

const infoCardBodyStyle = {
    padding: 0,
    paddingBottom: 18
};

const dateFormat = 'YYYY-MM-DD';

export class Reports extends Component {
    constructor(props) {
        super(props);

        this.state = { data: null, startDate: moment().subtract(1, 'months').format("YYYY-MM-DDT00:00:00.0"), endDate: moment().format("YYYY-MM-DDT00:00:00.0"), site: "production", loading: true, error: false, errorMessage: "" };

        this.getData = this.getData.bind(this);
        this.onDateRangeChange = this.onDateRangeChange.bind(this);
        this.onSiteChange = this.onSiteChange.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    onSiteChange(e) {
        this.setState({ site: e.target.value }, this.getData);
    };

    render() {
        return (
            <Fragment>
                <Layout title="Usage Stats" subTitle="View Lite Production/Development usage information by selected date range" additionalPageHeaderContent={
                    <Fragment>
                        <Radio.Group disabled={this.state.loading} defaultValue="production" onChange={this.onSiteChange} style={{ marginRight: 10 }}>
                            <Radio.Button value="production">Lite Production</Radio.Button>
                            <Radio.Button value="development">Lite Development</Radio.Button>
                        </Radio.Group>

                        <DatePicker.RangePicker disabled={this.state.loading} allowClear={false} defaultValue={[moment().subtract(1, 'months'), moment()]} onChange={this.onDateRangeChange} separator="-" />

                        <Button onClick={(e) => this.onPdfButtonClick(e)} style={{ float: 'right' }} type="primary" icon="download">Download as PDF</Button>
                    </Fragment>
                }>

                    {this.state.loading && <div style={{ textAlign: 'center', width: '100%' }}><Spin size="large" tip="Loading..." /></div>}

                    {this.state.error &&
                        <Result
                            status="error"
                            title="Could not retrieve the data"
                            subTitle={"An error occured while retrieving the data. Please adjust the date range or try again."}
                            extra={[
                                <Button type="primary" onClick={this.getData}>
                                    Try again
                                </Button>
                            ]}
                        />
                    }

                    {!this.state.loading && !this.state.error && this.state.data &&
                        <Fragment>
                            <Card style={{ marginBottom: 24 }}>
                                <Card.Grid hoverable={false} style={gridStyle}>
                                    <Statistic title="Topic views" value={this.state.data.topicVisitCount} prefix={<Icon type="eye" />} />
                                </Card.Grid>
                                <Card.Grid hoverable={false} style={gridStyle}>
                                    <Statistic title="Unique visitors" value={this.state.data.visitorCount} prefix={<Icon type="user" />} />
                                </Card.Grid>
                                <Card.Grid hoverable={false} style={gridStyle}>
                                    <Statistic title="Most popular device" value={this.state.data.topDevice} prefix={<Icon type="laptop" />} />
                                </Card.Grid>
                                <Card.Grid hoverable={false} style={gridStyle}>
                                    <Statistic title="Most popular browser" value={this.state.data.topBrowser} prefix={<Icon type="compass" />} />
                                </Card.Grid>
                            </Card>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Card title="Unique Visitors" style={infoCardStyle} bodyStyle={infoCardBodyStyle}>
                                        <GatChart type="line" config={uniqueVisitorsChartConfig} data={this.state.data.uniqueVisitors} />
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card title="Referers" style={infoCardStyle} bodyStyle={infoCardBodyStyle}>
                                        <GatChart type="bar" config={referersConfig} data={this.state.data.referers} />
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card title="Access Stats" style={infoCardStyle} bodyStyle={infoCardBodyStyle}>
                                        <GatChart type="stackbar" config={topicUsageConfig} data={this.state.data.accessStats} />
                                    </Card>
                                </Col>
                                {/* Seems to just duplicates data in Access Stats?
                                    <Col span={24}>
                                        <Card title="Topics" style={infoCardStyle} bodyStyle={infoCardBodyStyle}>
                                            <GatChart type="bar" config={topicsConfig} data={this.state.data.topics} />
                                        </Card>
                                    </Col>
                                */}
                                <Col span={24}>
                                    <Card title="Topic Visits" style={infoCardStyle} bodyStyle={infoCardBodyStyle}>
                                        <GatChart type="column" config={usageStats} data={this.state.data.topicAndDashboardIdUsageStats} />
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card title="Devices" style={infoCardStyle} bodyStyle={infoCardBodyStyle}>
                                        <GatChart type="bar" config={devicesChartConfig} data={this.state.data.devices} />
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card title="Browsers" style={infoCardStyle} bodyStyle={infoCardBodyStyle}>
                                        <GatChart type="bar" config={browsersChartConfig} data={this.state.data.browsers} />
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card title="Operating Systems" style={infoCardStyle} bodyStyle={infoCardBodyStyle}>
                                        <GatChart type="bar" config={operatingSystemsChartConfig} data={this.state.data.operatingSystems} />
                                    </Card>
                                </Col>
                            </Row>
                        </Fragment>
                    }
                </Layout>
            </Fragment>
        );
    }

    async onDateRangeChange(date, dateString) {
        var startDate = dateString[0] + "T00:00:00.0";
        var endDate = dateString[1] + "T00:00:00.0";

        this.setState({ startDate, endDate }, this.getData);
    }

    async onPdfButtonClick(event) {
        event.preventDefault();

        //this.setState({ loading: true, error: false, errorMessage: "" });

        const token = getToken();

        fetch('stats/usagepdf?inputStartDate=' + this.state.startDate + '&inputEndDate=' + this.state.endDate + '&site=' + this.state.site, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (!response.ok) { throw response; }

                return response.blob();
            })
            .then(blob => saveAs(blob, "Usage Report.pdf"));
    }

    getData() {
        this.setState({ loading: true, error: false, errorMessage: "" });

        const token = getToken();

        fetch('stats/usage?inputStartDate=' + this.state.startDate + '&inputEndDate=' + this.state.endDate + '&site=' + this.state.site, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (!response.ok) { throw response; }

                return response.json();
            })
            .then(json => {
                this.setState({ data: json, loading: false, error: false, errorMessage: "" });
            })
            .catch(err => {
                err.text().then(errorMessage => {
                    this.setState({ loading: false, error: true, errorMessage });
                })
            })
    }
}