import React, { Component } from 'react';
import { Route } from 'react-router';
import { withAdalLoginApi } from './components/api-authorization/adalConfig';
import { Result, Button, Spin } from 'antd';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { TopicEditor } from './components/TopicEditor';
import { TopicList } from './components/TopicList';
import { DatasetList } from './components/DatasetList';
import { DatasetEditor } from './components/DatasetEditor';
import { DevTools } from './components/DevTools';
import { Reports } from './components/Reports';

import './custom.css'
import { Fragment } from 'react';
import { BackTop } from 'antd';

const getLoadingSpinner = () => {
    return <Layout title="Authentication"><div style={{ textAlign: 'center', width: '100%' }}><Spin size="large" tip="Loading..." /></div></Layout>;
};

const getErrorPage = (error) => {
    return <Layout title="Authentication error">
            <Result
            status="warning"
            title="There was an error"
            subTitle={error.message}
            />
        </Layout>
}

const ProtectedDatasetList = withAdalLoginApi(DatasetList, () => getLoadingSpinner(),
    (error) => getErrorPage(error));
const ProtectedDatasetEditor = withAdalLoginApi(DatasetEditor, () => getLoadingSpinner(),
    (error) => getErrorPage(error));
const ProtectedTopicList = withAdalLoginApi(TopicList, () => getLoadingSpinner(),
    (error) => getErrorPage(error));
const ProtectedTopicEditor = withAdalLoginApi(TopicEditor, () => getLoadingSpinner(),
    (error) => getErrorPage(error));
const ProtectedDevTools = withAdalLoginApi(DevTools, () => getLoadingSpinner(),
    (error) => getErrorPage(error));
const ProtectedReports = withAdalLoginApi(Reports, () => getLoadingSpinner(),
    (error) => getErrorPage(error));

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Fragment>
                <Route exact path='/' component={Home} />
                <Route exact path='/dataset_builder' component={ProtectedDatasetList} />
                <Route exact path='/dataset_builder/create' component={ProtectedDatasetEditor} />
                <Route exact path='/dataset_builder/edit/:id' component={ProtectedDatasetEditor} />
                <Route exact path='/dashboard_builder' component={ProtectedTopicList} />
                <Route exact path='/dashboard_builder/create' component={ProtectedTopicEditor} />
                <Route exact path='/dashboard_builder/edit/:id' component={ProtectedTopicEditor} />
                <Route exact path='/dev_tools' component={ProtectedDevTools} />
                <Route exact path='/reports' component={ProtectedReports} />
                <BackTop />
            </Fragment>
        );
    }
}
