import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { authContext } from './adalConfig';

import { Layout, Menu, Breadcrumb, Icon, Avatar } from 'antd';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        //var user = authContext.getUser();
        var profile = authContext._user && authContext._user.profile;

        //console.log(authContext);

        if (profile) {
            this.setState({ userName: profile.name, isAuthenticated: true });;
        }
    }

    componentWillUnmount() {

    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            return this.anonymousView();
        } else {
            return this.authenticatedView();
        }
    }

    authenticatedView() {
        return (<Menu
            theme="dark"
            mode="horizontal"
            selectable={false}
            style={{ lineHeight: '64px', float: 'right', paddingRight: 0 }}
        >


            <Menu.SubMenu
                style={{ paddingRight: 0 }}
                title={
                    <span>
                        <Avatar icon="user" style={{ marginRight: '10px' }} />
                        {this.state.userName}
                        <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                </span>
                }
            >



                <Menu.Item key="logout"><a onClick={() => authContext.logOut()}>Logout</a></Menu.Item>

            </Menu.SubMenu>

        </Menu>);

    }

    anonymousView() {
        return (<Menu
            theme="dark"
            mode="horizontal"
            selectable={false}
            style={{ lineHeight: '64px', float: 'right' }}
        >
            <Menu.Item key="logout">
                <a onClick={() => authContext.login()}><Icon type="login" /> Login</a>
            </Menu.Item>
        </Menu>);
    }
}
