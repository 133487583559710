import React, { Component, Fragment } from 'react';
import { Card } from 'antd';
import { Layout } from './Layout'

export class Home extends Component {
  render () {
    return (
        <Fragment>
            <Layout title="Home">
                <Card title="Welcome to Gavurin Admin Tools">
                    <p style={{ margin: 0 }}>Hello and welcome to Gavurin Admin Tools. Sign in with your Microsoft account when prompted to create and edit datasets, dashboards or view usage stats.</p>
                </Card>
            </Layout>
      </Fragment>
    );
  }
}
