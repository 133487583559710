import React, { Component, Fragment } from 'react';
import { Card, Icon, Button, Tabs, Collapse, message } from 'antd';
import { withRouter } from 'react-router-dom';
import WrappedDatasetSumaryEditor from './DatasetSumaryEditor';
import WrappedDrillValueEditor from './DrillValueEditor';
import ValidityRuleEditor from './ValidityRuleEditor';
import WrappedAggregationRuleEditor from './AggregationRuleEditor';
import WrappedDrillColumnDefinitionEditor from './DrillColumnDefinitionEditor';
import { getToken } from './api-authorization/adalConfig';
import { Layout } from './Layout'

const { TabPane } = Tabs;

export class DatasetEditor extends Component {
    static displayName = DatasetEditor.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            dataSetId: -1,
            validityRules: [],
            drillColumnDefinitions: [],
            aggregationRules: []
        };

        this.onValidateFormValues = this.onValidateFormValues.bind(this);
        this.onDatasetSave = this.onDatasetSave.bind(this);
        this.onDrillValueChange = this.onDrillValueChange.bind(this);
        this.onValidityRuleChange = this.onValidityRuleChange.bind(this);
        this.deleteValidityRule = this.deleteValidityRule.bind(this);
        this.onNewValidityRule = this.onNewValidityRule.bind(this);
        this.onAggregationRulesChange = this.onAggregationRulesChange.bind(this);
        this.onDrillColumnDefinitionChange = this.onDrillColumnDefinitionChange.bind(this);
        this.deleteDrillColumnDefinition = this.deleteDrillColumnDefinition.bind(this);
        this.onNewDrillColumnDefinition = this.onNewDrillColumnDefinition.bind(this);
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        console.log("LOADING DATASET EDITOR BY DATASET ID", params.id);

        if (params.id) {
            // Get topic by id
            this.getDatasetDetailsById(params.id);
        }
        else {
            this.getDatasetDetailsById(-1);
        }
    }

    onValidateFormValues(formValues) {
        console.log(formValues);

        this.setState(formValues);
    }

    onDrillValueChange(formValues) {
        console.log(formValues);

        this.setState(formValues);
    }

    onValidityRuleChange(newValidityRuleState) {
        console.log("UPDATING VALIDITY RULE STATE", newValidityRuleState);

        var newValidityRules = this.state.validityRules;

        var indexToUpdate = newValidityRules.findIndex(function (item) {
            return item.guid === newValidityRuleState.guid;
        });

        newValidityRules[indexToUpdate] = newValidityRuleState;

        this.setState({ validityRules: newValidityRules });

        console.log("UPDATED VALIDITY RULE STATE", this.state);
    }

    onAggregationRulesChange(newAggregationRuleState) {
        console.log("UPDATING AGGREGATION RULE STATE", newAggregationRuleState);

        var newAggregationRules = this.state.aggregationRules;

        newAggregationRules[0] = newAggregationRuleState;

        this.setState({ aggregationRules: newAggregationRules });

        console.log("UPDATED AGGREGATION RULE STATE", this.state);
    }

    onDrillColumnDefinitionChange(newDrillColumnDefinitionState) {
        console.log("UPDATING DRILL COLUMN DEFINITION STATE", newDrillColumnDefinitionState);

        var newDrillColumnDefinitions = this.state.drillColumnDefinitions;

        var indexToUpdate = newDrillColumnDefinitions.findIndex(function (item) {
            return item.guid === newDrillColumnDefinitionState.guid;
        });

        newDrillColumnDefinitions[indexToUpdate] = newDrillColumnDefinitionState;

        this.setState({ drillColumnDefinitions: newDrillColumnDefinitions });

        console.log("UPDATED DRILL COLUMN DEFINITION STATE", this.state);
    }

    onDatasetSave() {
        console.log("SAVING DATASET");

        this.saveDataset();
    }

    async onNewValidityRule() {
        console.log("ADDING NEW VALIDITY RULE");

        const token = getToken();
        const response = await fetch('datasets/new_validity_rule', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        var newValidityRules = this.state.validityRules;
        newValidityRules.push(data);

        this.setState({ validityRules: newValidityRules })
    }

    async onNewDrillColumnDefinition() {
        console.log("ADDING NEW DRILL COLUMN DEFINITION");

        const token = getToken();
        const response = await fetch('datasets/new_drill_column_definition', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        var newDrillColumnDefinitions = this.state.drillColumnDefinitions;
        newDrillColumnDefinitions.push(data);

        this.setState({ drillColumnDefinitions: newDrillColumnDefinitions })
    }

    async getDatasetDetailsById(datasetId) {
        const token = getToken();
        const response = await fetch('datasets/details?id=' + datasetId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState(data);

        this.setState({ loading: false });
    }

    async saveDataset() {
        const token = getToken();

        const response = await fetch('datasets/save', {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`, 'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(this.state)
        });

        const data = response.json().then(this.props.history.push('/dataset_builder')).then(message.success('Your changes have been saved.'));
    }

    async deleteValidityRule(validityRuleGuid, event) {

        event.stopPropagation();

        console.log(validityRuleGuid);

        var filteredValidityRules = await this.state.validityRules.filter(r => r.guid !== validityRuleGuid);

        await this.setState({
            validityRules: filteredValidityRules
        });

        console.log(this.state.validityRules, this.state.validityRules);
    }

    async deleteDrillColumnDefinition(drillColumnDefinitionGuid, event) {

        event.stopPropagation();

        console.log(drillColumnDefinitionGuid);

        var filteredDrillColumnDefinitions = await this.state.drillColumnDefinitions.filter(d => d.guid !== drillColumnDefinitionGuid);

        await this.setState({
            drillColumnDefinitions: filteredDrillColumnDefinitions
        });

        console.log(this.state.drillColumnDefinitions, this.state.drillColumnDefinitions);

    }

    render() {
        var scope = this;

        return (
            <Fragment>
                <Layout
                    style={{
                        padding: '0 0',
                        marginTop: '12px',
                        marginBottom: '24px'
                    }}
                    onBack={() => this.props.history.push('/dataset_builder')}
                    title={this.state.id != -1 ? this.state.commonName : "Create a new dataset"}
                    extra={[
                        <Button type="primary" icon="save" onClick={this.onDatasetSave}>
                            Save dataset
                        </Button>
                    ]}
                >
                    <Card>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Summary" key="1">
                        <WrappedDatasetSumaryEditor
                            commonName={this.state.commonName}
                            description={this.state.description}
                            keywords={this.state.keywords}
                            sources={this.state.sources}
                            buildDomain={this.state.buildDomain}
                            storedProcedureName={this.state.storedProcedureName}
                            descriptionHint={this.state.descriptionHint}
                            mapPresentationName={this.state.mapPresentationName}
                            chartYAxisLabel={this.state.chartYAxisLabel}
                            onValidateFormValues={this.onValidateFormValues}
                        />
                    </TabPane>
                    <TabPane tab={"Drill details"} key="2">

                        <WrappedDrillValueEditor
                            drillLookupTable={this.state.drillLookupTable}
                            defaultDrillValue={this.state.defaultDrillValue}
                            onValidateFormValues={this.onDrillValueChange}
                        />

                    </TabPane>
                    <TabPane tab={"Validity rules (" + this.state.validityRules.length + ")"} key="3">

                        <div style={{ marginBottom: '11px', display: 'inline-block', width: '100%' }}>
                            <Button onClick={this.onNewValidityRule} style={{ float: 'right' }}>
                                <Icon type="plus" /> New validity rule
                            </Button>
                        </div>

                        <Collapse bordered={false} defaultActiveKey={['1']}>
                            {scope.state.validityRules.map((validityRule, index) => (
                                <Collapse.Panel header={"Validity rule (" + validityRule.guid + ")"} key={"ValidityRule" + index} extra={<Button onClick={e => { scope.deleteValidityRule(validityRule.guid, e) }} type="link" size="small" icon="delete">Delete</Button>} >
                                    <ValidityRuleEditor
                                        validityRuleEditorState={validityRule}
                                        onValidateFormValues={scope.onValidityRuleChange}
                                        key={validityRule.guid}
                                    />
                                </Collapse.Panel>
                            ))}
                        </Collapse>

                    </TabPane>
                    <TabPane tab={"Drill column definitions (" + this.state.drillColumnDefinitions.length + ")"} key="4">

                        <div style={{ marginBottom: '11px', display: 'inline-block', width: '100%' }}>
                            <Button onClick={this.onNewDrillColumnDefinition} style={{ float: 'right' }}>
                                <Icon type="plus" /> New drill column definition
                            </Button>
                        </div>

                        <Collapse bordered={false} defaultActiveKey={['1']}>
                            {scope.state.drillColumnDefinitions.map((drillColumnDefinition, index) => (
                                <Collapse.Panel header={"Drill column definition (" + drillColumnDefinition.guid + ")"} key={"DrillColumnDefinition" + index} extra={<Button onClick={e => { scope.deleteDrillColumnDefinition(drillColumnDefinition.guid, e) }} type="link" size="small" icon="delete">Delete</Button>} >
                                    <WrappedDrillColumnDefinitionEditor
                                        drillColumnDefinitionEditorState={drillColumnDefinition}
                                        onValidateFormValues={scope.onDrillColumnDefinitionChange}
                                        key={drillColumnDefinition.guid}
                                    />
                                </Collapse.Panel>
                            ))}
                        </Collapse>

                    </TabPane>
                    <TabPane tab={"Aggregation rules"} key="5">
                        <WrappedAggregationRuleEditor
                            aggregationRules={this.state.aggregationRules}
                            onValidateFormValues={this.onAggregationRulesChange}
                        />
                    </TabPane>
                        </Tabs>
                        </Card>
                </Layout>
            </Fragment>
        );
    }
}
