import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Button, Slider, InputNumber } from 'antd';
import { getToken } from './api-authorization/adalConfig';

const { Option } = Select;

class DateSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = { date: this.props.date };

        this.updateDateSelection = this.updateDateSelection.bind(this);
        this.getDateSelectorStateByDatasetId = this.getDateSelectorStateByDatasetId.bind(this);
        this.updateDateSelectorStateFromServer = this.updateDateSelectorStateFromServer.bind(this);
        this.setInitialDatesFromState = this.setInitialDatesFromState.bind(this);
    }

    componentDidMount() {
        if (this.props.datasetId) {
            this.getDateSelectorStateByDatasetId(this.props.datasetId, this.props.isRangeSelectionEnabled);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.datasetId !== this.props.datasetId) {
            if (this.props.datasetId) {
                this.getDateSelectorStateByDatasetId(this.props.datasetId, this.props.isRangeSelectionEnabled);
            }
        }
    }

    updateDateSelection(value) {
        if (!this.props.isRangeSelectionEnabled) {
            this.setState({
                selectedEndDate: this.state.enumeratedDates[value].value,
                selectedEndDateIndex: this.state.enumeratedDates[value].index
            });

            this.props.form.setFieldsValue({
                date: this.state.enumeratedDates[value].value
            });

            this.props.onBlurHandler();
        }
        else {
            this.setState({
                selectedStartDate: this.state.enumeratedDates[value[0]].value,
                selectedEndDate: this.state.enumeratedDates[value[1]].value,
                selectedStartDateIndex: this.state.enumeratedDates[value[0]].index,
                selectedEndDateIndex: this.state.enumeratedDates[value[1]].index
            });

            this.props.form.setFieldsValue({
                minDate: this.state.enumeratedDates[value[0]].value,
                maxDate: this.state.enumeratedDates[value[1]].value
            });

            this.props.onBlurHandler();
        }
    }

    updateDateSelectorStateFromServer() {
        if (this.props.datasetId) {
            this.getDateSelectorStateByDatasetId(this.props.datasetId, this.props.isRangeSelectionEnabled);
        }
    }

    async getDateSelectorStateByDatasetId(datasetId, isRangeSelectionEnabled) {
        var dateParams = isRangeSelectionEnabled ? '&selectedStartDate=' + this.props.date[0] + '&selectedEndDate=' + this.props.date[1] : '&selectedEndDate=' + this.props.date;

        const token = getToken();
        const response = await fetch('topics/frequencies_and_dates?datasetId=' + datasetId + '&isRangeSelectionEnabled=' + isRangeSelectionEnabled + dateParams, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        await this.setState(data);
        await this.setInitialDatesFromState();
    }

    setInitialDatesFromState() {
        if (this.state.enumeratedDates) {
            if (this.props.isRangeSelectionEnabled) {
                var startDateIndex = this.state.selectedStartDateIndex;
                var endDateIndex = this.state.selectedEndDateIndex;
                this.updateDateSelection([startDateIndex,endDateIndex]);
            }
            else {
                var dateIndex = this.state.selectedEndDateIndex;
                this.updateDateSelection(dateIndex);
            }
        }
    }

    render() {
        const scope = this;

        const { getFieldDecorator } = this.props.form;

        const enumeratedDateSliderMarks = scope.state
            && scope.state.enumeratedDates
            && Object.assign({}, ...Object.keys(scope.state.enumeratedDates).map(function (key, index) {
                if (scope.state.enumeratedDates[key].displayOnSlider === true) {
                    var newDateObject = {};
                    newDateObject[scope.state.enumeratedDates[key].index] = scope.state.enumeratedDates[key].label;

                    return newDateObject;
                }
            }));

        return (

            <Fragment>

                {this.state.enumeratedDates &&
                    <Fragment>
                        <Form.Item label="Date">
                            {this.props.isRangeSelectionEnabled &&
                                <p>Selected dates: {this.state.enumeratedDates[this.state.selectedStartDateIndex].label} to {this.state.enumeratedDates[this.state.selectedEndDateIndex].label}</p>
                            }

                            {!this.props.isRangeSelectionEnabled &&
                                <p>Selected date: {this.state.enumeratedDates[this.state.selectedEndDateIndex].label}</p>
                            }

                            {this.props.isRangeSelectionEnabled && 
                                // Required so we can manually return minDate/maxDate via updateDateSelection from a single slider
                                <Fragment>
                                    {getFieldDecorator('minDate', {
                                    })(<Fragment></Fragment>)}

                                    {getFieldDecorator('maxDate', {
                                    })(<Fragment></Fragment>)}
                                </Fragment>
                            }

                            {getFieldDecorator('date', {
                                initialValue: this.props.date,
                                valuePropName: 'dateValue'
                            })(
                                <Slider dateValue={this.props.isRangeSelectionEnabled ? [this.state.selectedStartDate, this.state.selectedEndDate] : this.state.selectedEndDate} value={this.props.isRangeSelectionEnabled ? [this.state.selectedStartDateIndex, this.state.selectedEndDateIndex] : this.state.selectedEndDateIndex} min={this.state.minDateIndex} max={this.state.maxDateIndex} range={this.props.isRangeSelectionEnabled} marks={enumeratedDateSliderMarks} tipFormatter={(value) => { return scope.state.enumeratedDates[value].label; }} onChange={this.updateDateSelection} onAfterChange={this.updateDateSelection} />
                            )}
                    </Form.Item>
                    <Form.Item label="Max date offset">
                        {getFieldDecorator('maxDateOffset', {
                            initialValue: this.props.maxDateOffset,
                            rules: [],
                        })(<InputNumber placeholder="Enter a negative numeric value of date fragments to offset end year, quarter, month or week (optional)" max={-1} style={{ width: '100%' }} onBlur={this.props.onBlurHandler} />)}
                    </Form.Item>
                    {this.props.hasSecondaryDate &&
                        <Form.Item label="Max secondary date offset">
                            {getFieldDecorator('maxSecondaryDateOffset', {
                                initialValue: this.props.maxSecondaryDateOffset,
                                rules: [],
                            })(<InputNumber placeholder="Enter a negative numeric value of date fragments to offset end year, quarter, month or week (optional)" max={-1} style={{ width: '100%' }} onBlur={this.props.onBlurHandler} />)}
                        </Form.Item>
                    }
                    </Fragment>
                }
    
            </Fragment>

        );
    }
}

export default DateSelector;