import React, { Component } from 'react';
import { LineChart, BarChart, StackedBarChart, StackedColumnChart } from '@opd/g2plot-react'
import { Fragment } from 'react';

export default class GatChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chart: null,
            data: null,
            config: {}
        };

        this.onChartDidMount = this.onChartDidMount.bind(this);
        this.updateChart = this.updateChart.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if ((JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)) ||
            (JSON.stringify(prevState.config) !== JSON.stringify(this.state.config))) {
            this.updateChart();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if ((JSON.stringify(props.data) !== JSON.stringify(state.data)) ||
            (JSON.stringify(props.config) !== JSON.stringify(state.config))) {
            return {
                data: props.data,
                config: props.config
            };
        }

        // Return null if the state hasn't changed
        return null;
    }

    onChartDidMount(chart) {
        this.setState({ chart }, this.updateChart);
    }

    updateChart() {
        if (this.state.chart && this.state.chart.canvasController) {
            this.state.chart.canvasController.plot.updateConfig({ });
            this.state.chart.canvasController.plot.render();   
        }
    }

    render() {

        return (
            <Fragment>
                {this.props.type === "line" && this.state.data &&
                    <LineChart {...this.state.config} data={this.state.data} onMount={this.onChartDidMount} />
                }

                {this.props.type === "bar" && this.state.data &&
                    <BarChart {...this.state.config} data={this.state.data} onMount={this.onChartDidMount} />
                }

                {this.props.type === "stackbar" && this.state.data &&
                    <StackedBarChart {...this.state.config} data={this.state.data} onMount={this.onChartDidMount} />
                }

                {this.props.type === "column" && this.state.data &&
                    <StackedColumnChart {...this.state.config} data={this.state.data} onMount={this.onChartDidMount}/>
                }
            </Fragment>
        );
    }
}