import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form, Select, Input, Divider, InputNumber, Slider, Checkbox, Card } from 'antd';

const { Option } = Select;

class TextCardEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.props.cardEditorState;
    }

    componentDidMount() {

    }

    handleFormChange = e => {

        this.props.form.validateFields((err, values) => {
            this.setState(values, () => {
                this.props.onValidateFormValues(this.state);
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const widthSliderMarks = {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            10: '10',
            11: '11',
            12: '12'
        };

        return (

            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left" onChange={this.handleFormChange}>
                <Card title="Presentation" size="small" style={{ marginBottom: '16px' }}>
                <Form.Item label="Title">
                    {getFieldDecorator('title', {
                        initialValue: this.state.title,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Show in a card">
                    {getFieldDecorator('isBoxed', {
                        initialValue: this.state.isBoxed,
                        rules: []
                    })(<Checkbox checked={this.state.isBoxed} />)}
                </Form.Item>
                <Form.Item label="Show card header">
                    {getFieldDecorator('showCardHeader', {
                        initialValue: this.state.showCardHeader,
                        rules: []
                    })(<Checkbox checked={this.state.showCardHeader} />)}
                    </Form.Item>
                    <Form.Item label="Width (1-12 columns)">
                        {getFieldDecorator('width', {
                            initialValue: this.state.width,
                            rules: []
                        })(<Slider min={1} max={12} marks={widthSliderMarks} onAfterChange={this.handleFormChange} />)}
                    </Form.Item>
                </Card>
                <Card title="Content" size="small" style={{ marginBottom: '16px' }}>
                <Form.Item label="Text">
                    {getFieldDecorator('text', {
                        initialValue: this.state.text,
                        rules: [{ required: true, message: 'Please enter' }]
                    })(<Input.TextArea rows={5} />)}
                    </Form.Item>
                </Card>
                <Card title="Extras" size="small">
                <Form.Item label="Inner CSS">
                    {getFieldDecorator('innerCss', {
                        initialValue: this.state.innerCss,
                        rules: []
                    })(<Input.TextArea rows={5} />)}
                </Form.Item>
                <Form.Item label="Outer CSS">
                    {getFieldDecorator('outerCss', {
                        initialValue: this.state.outerCss,
                        rules: []
                    })(<Input.TextArea rows={5} />)}
                    </Form.Item>
                </Card>
            </Form>

        );
    }
}

const WrappedTextCardEditor = Form.create({ name: 'textCardEditor' })(TextCardEditor);

export default WrappedTextCardEditor;